import { isPlatformBrowser } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, Input, PLATFORM_ID, ViewEncapsulation } from '@angular/core';
import { ClickActions } from '@core-constants/action-type.const';
import { EAppEventName } from '@core-constants/app-events.const';
import { ErrorMessageConst } from '@core-constants/error-message.const';
import { FBP_CURRENCIES, FBP_PRODUCT_TYPES } from '@core-constants/fbp-trackting.const';
import { EGtmSection } from '@core-constants/gtm-const';
import { InteractiveToasterConfigurations } from '@core-constants/interactive-toaster.const';
import { ServiceConst } from '@core-constants/product-service.const';
import { ReferenceConst } from '@core-constants/reference.const';
import { PixelService } from '@core-data-services/pixel.data-service';
import { ServicesDisplayDataService } from '@core-data-services/services-display.data-service';
import { ShoppingCartDataService } from '@core-data-services/shopping-cart.data-service';
import { PromotionHelper } from '@core-helpers/promotion.helper';
import { PixelPurchaseDataModel } from '@core-models/pixel.model';
import { IPlan } from '@core-models/plans.model';
import { ICupon } from '@core-models/service-package.model';
import { GtmTrackingService } from '@shared-base/gtm-tracking.service';
import { BroadcastService } from '@shared-services/broadcast.service';
import { InteractiveToasterService } from '@shared-services/interactive-toast.service';
import { ToastService } from '@shared-services/toast.service';
import { TranslateService } from '@shared-services/translate.service';
import { HtmlExtractUtil } from '@shared-utils/htmlextrac.util';
import { Tools } from '@shared-utils/tools.util';

@Component({
  selector: 'app-plan-item',
  templateUrl: './app-service-plan-item.component.html',
  styleUrls: ['./app-service-plan-item.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class ServicePlanItemComponent
{
  public img: string = "";
  public title: string = "";
  private name: string = "";
  public textButton: string = "";
  public textRecommended: string = "";
  public description: string = "";
  public characteristics: string = "";
  public currentPrice: string = "";
  public lastPrice: string = "";
  public cartServiceData: string = "";
  public action: string = "";
  public url: string = "";
  public packageId: number = null;
  public gtmKey: string = "";
  public plan_layout = false;
  public quantity: number = 0;
  public showQuantityControl: boolean = false;

  public recommendedImage: string = '/images/recomendado.svg';
  public priceBackground: string = '#ECEDEF';
  public cardBackground: string = '#F9F9F9';
  public titleBackgroud: string = '';
  public textButtonGetAdvice: string = 'ASESÓRATE AHORA';
  public whatsappurl = ReferenceConst.WhatsAppUrl;

  private _recommended: string = "";
  public comboServices: ICupon = undefined;
  public textButtonCombo: string = "";

  public eventLocationSite: string = "";

  @Input() public imgOnBottom: boolean = true;

  @Input() public set plan(value: IPlan)
  {
    const planInfo: IPlan = value;

    if (planInfo)
    {
      const planTranslation = planInfo?.traducciones[0];
      this.plan_layout = Object.prototype.hasOwnProperty.call(planInfo, 'plan_layout');
      this._recommended = planInfo.recomendado;
      this.priceBackground = planInfo.color_area_precio;
      this.titleBackgroud = this.priceBackground;
      this.cardBackground = '#FFFFFF';
      this.showQuantityControl = planInfo.cantidad;
      this.name = planInfo.nombre;

      this.gtmKey = planInfo.clave_gtm;
      this.cartServiceData = planInfo.id_servicio_carrito?.valor;
      this.action = planInfo.tipo_accion;
      this.url = planInfo.url_redireccionamiento;
      this.packageId = planInfo.id_paquetes?.id;

      if (planTranslation)
      {
        this.title = planTranslation.titulo;
        this.currentPrice = planTranslation.precio_actual;
        this.lastPrice = planTranslation.antes_precio;
        this.textButton = planTranslation.texto_boton;
        this.description = planTranslation.descripcion;
        this.characteristics = planTranslation.caracteristicas;
        this.img = planTranslation?.img_plan?.id;
        this.textRecommended = planTranslation.texto_recomendado;
        this.textButtonCombo = planTranslation.texto_boton_combo;
      }

      if (this.packageId)
      {
        this.getServicesPackage();
      }
    }
  }

  @Input() public set location(value: string)
  {
    this.eventLocationSite = value;
  }

  constructor(@Inject(PLATFORM_ID) private platformId,
    protected toast: ToastService,
    protected interactiveToast: InteractiveToasterService,
    private shoppingCartDataService: ShoppingCartDataService,
    private servicesDataService: ServicesDisplayDataService,
    private promotionHelper: PromotionHelper,
    private changeDetectorRef: ChangeDetectorRef,
    private pixelService: PixelService,
    protected translateService: TranslateService) { }

  public get recommended(): boolean
  {
    return this._recommended == "si";
  }

  public onButtonClick(): void
  {
    if (this.action === ClickActions.Types.Redirect)
    {
      if (isPlatformBrowser(this.platformId))
      {
        window.location.href = this.url;
      }
    }
    else
    {
      this.addToCart();
    }
  }

  public getServicesPackage(): void
  {
    this.servicesDataService.getPackageById(this.packageId).subscribe({
      next: (response: ICupon) =>
      {
        if (response)
        {
          this.comboServices = response;
          this.changeDetectorRef.detectChanges();
        }
      }
    });
  }

  public onComboButtonClicked(): void
  {
    this.promotionHelper.addToCart(this.comboServices, EGtmSection.Plans, this.eventLocationSite);
  }

  public onDecrementQuantityClicked(): void
  {
    this.quantity === 0 ? '' : this.quantity--;
  }

  public onIncrementQuantityClicked(): void
  {
    this.quantity < 100 ? this.quantity++ : '';
  }

  public addToCart(): void
  {
    this.showQuantityControl === false || this.quantity == 0 ? this.quantity = 1 : '';
    this.shoppingCartDataService.addService(this.cartServiceData, this.quantity).subscribe({
      next: (response: any) =>
      {
        this.interactiveToast.showMessage(InteractiveToasterConfigurations.GoToCart);

        BroadcastService.Instance.broadcast(EAppEventName.OnAddToCart, response.cartId);
        GtmTrackingService.addToCartPresenceServiceEvent(this.cartServiceData, this.currentPrice, this.quantity, this.eventLocationSite);
        this.trackEvent(this.currentPrice, this.quantity, this.cartServiceData);
      },
      error: () =>
      {
        this.toast.setErrorToast(ErrorMessageConst.OperationFailed);
      }
    });
  }
  
  private trackEvent(priceHtml: string, quantity: number, product: string): void
  {
    const price = HtmlExtractUtil.parsePrice(priceHtml);
    const aplId = Tools.convertServiceData(product)['APL_ID'];
    const productName = ServiceConst.AdditionalServicePlans.get(aplId);
    const data: PixelPurchaseDataModel = new PixelPurchaseDataModel(Number.parseFloat(price), FBP_CURRENCIES.MXN, productName, FBP_PRODUCT_TYPES.SINGLE_PRODUCT, quantity, [aplId.toString()]);
    this.pixelService.trackPurchaseEvent(data);
  }
}
