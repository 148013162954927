import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HeaderNamesConst } from '@core-constants/header-names.const';
import { UserAccessDataService } from '@core-data-services/security/user-access.data-service';
import { TokenManager } from '@core-managers/token.manager';
import { ITokenMSVCResponse } from '@core-models/msvc-tokens.model';
import { Environment } from '@environments';
import { ToastService } from '@shared-services/toast.service';
import { BehaviorSubject, catchError, filter, finalize, Observable, switchMap, take, throwError } from 'rxjs';

@Injectable()
export class AddAuthorizationMSVCToken implements HttpInterceptor
{
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private backendError: string = "Unauthorized: Invalid Token";

  constructor(private tokenManager: TokenManager,
    private userAccessDataService: UserAccessDataService,
    private toast: ToastService) { }

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>>
  {
    if (request.url.includes(Environment.MicroserviceURL))
    {
      let authRequest = request;
      const token = this.tokenManager.getMSVCToken();

      if (this.verifyTokenValue(token))
      {
        authRequest = this.addTokenHeader(request, token);
      }

      return next.handle(authRequest)
        .pipe(catchError(error =>
        {
          if (error instanceof HttpErrorResponse && error.status === 403 && error.error?.message == this.backendError)
          {
            return this.handle403Error(authRequest, next);
          }

          return throwError(() => error);
        }));
    }

    return next.handle(request);
  }

  private addTokenHeader(request: HttpRequest<any>, token: string): HttpRequest<any>
  {
    return request.clone({ headers: request.headers.set(HeaderNamesConst.MSVCToken, `Bearer ${token}`) });
  }

  private handle403Error(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>
  {
    if (!this.isRefreshing)
    {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.userAccessDataService.getMSVCToken().pipe(
        switchMap((response: ITokenMSVCResponse) =>
        {
          this.tokenManager.saveMSVCToken(response.accessToken);

          this.refreshTokenSubject.next(response.accessToken);

          return next.handle(this.addTokenHeader(request, response.accessToken));
        }),
        catchError((error) =>
        {
          this.toast.setErrorToast(error.error?.message);

          return throwError(() => error);
        }),
        finalize(() =>
        {
          this.isRefreshing = false;
        })
      );
    }
    else
    {
      return this.refreshTokenSubject.pipe(
        filter(token => token !== null),
        take(1),
        switchMap((token) => next.handle(this.addTokenHeader(request, token)))
      );
    }
  }

  private verifyTokenValue(token: string): boolean
  {
    return token && token != '';
  }
}
