import { DestroyRef, Injectable } from '@angular/core';
import { LocalStorageConst, SesionStorageConst } from '@core-constants/storage.const';
import { RouteHelper } from '@core-helpers/route.helper';
import { IAdditionalPurchaseSuggestionSelected } from '@core-models/purchase-suggestion.model';
import { UserAccessResponse } from '@core-models/user-access.model';
import { LocalStorageService } from '@shared-services/local-storage.service';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Subject, debounceTime, distinctUntilChanged } from 'rxjs';
import { SettingsManager } from './settings.manager';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SessionConst } from '../constants/session.const';

@Injectable({ providedIn: 'root' })
export class TokenManager
{
  public changeDetection = new Subject<number>;
  public currentValue = new BehaviorSubject(0);
  public currentValue$ = this.currentValue.asObservable();

  constructor(public routeHelper: RouteHelper,
    public localStorageService: LocalStorageService,
    public cookieService: CookieService,
    public settingsManager: SettingsManager,
    private destroyRef$: DestroyRef)
  {
    this.registerEventListeners();
  }

  public registerEventListeners(): void
  {
    this.currentValue$.pipe(debounceTime(400), distinctUntilChanged(), takeUntilDestroyed(this.destroyRef$)).subscribe({
      next: (value: number) =>
      {
        this.changeDetection.next(value);
      }
    });
  }

  public signOut(): void
  {
    this.removeToken();
    this.removeRefreshToken();
    this.removeUser();
    this.removeCartInformation();
    this.removeSFData();
  }

  public saveUserAuthenticationData(data: UserAccessResponse.IUser): void
  {
    this.saveToken(data.bearerToken);
    this.saveRefreshToken(data.refreshToken);
    this.saveCartCookie(data.cartId);
    this.saveUser(data.userInfoModel);
    this.saveSFData(data.userInfoModel);
  }

  // "removeCartInformation" method deletes cart cookie, "removeAditionalCartInformation" method doesnt

  public removeAditionalCartInformation(): void
  {
    this.removeCartItems();
    this.removeCartCount();
    this.removeCartTotal();
    this.removeCartCoupon();
  }

  public removeCartInformation(): void
  {
    this.removeCartCookie();
    this.removeAditionalCartInformation();
  }

  // ********************************************************************
  //#region CouponToApply
  // ********************************************************************

  public saveCartCoupon(couponToApply: string): void
  {
    this.localStorageService.removeItem(SesionStorageConst.CartCoupon);
    this.localStorageService.setItem(SesionStorageConst.CartCoupon, couponToApply);
  }

  public getCartCoupon(): boolean
  {
    const couponToApply = this.localStorageService.getItem(SesionStorageConst.CartCoupon);
    return couponToApply && couponToApply != "";
  }

  private removeCartCoupon(): void
  {
    this.localStorageService.removeItem(SesionStorageConst.CartCoupon);
  }

  // #endregion

  // ********************************************************************
  //#region Internal Base Methods
  // ********************************************************************

  private saveCookie(key: string, value: any, isPlainText: boolean = false): void
  {
    const valueToSet = isPlainText === true ? value : JSON.stringify(value);

    this.remove(key);

    this.cookieService.set(key, valueToSet, null, '/', this.routeHelper.domainName);
  }

  private remove(key: string): void
  {
    this.cookieService.delete(key, "/", this.routeHelper.domainName);
    this.localStorageService.removeItem(key);
  }

  // #endregion

  // ********************************************************************
  //#region Token
  // ********************************************************************

  public saveToken(token: string): void
  {
    this.saveCookie(SesionStorageConst.TokenKey, token);
  }

  public getToken(): string | undefined
  {
    const res = this.cookieService.get(SesionStorageConst.TokenKey);
    return res ? JSON.parse(res) : undefined;
  }

  private removeToken(): void
  {
    this.remove(SesionStorageConst.TokenKey);
  }
  // #endregion

  // ********************************************************************
  //#region Refresh Token
  // ********************************************************************

  public saveRefreshToken(refreshToken: string): void
  {

    this.saveCookie(SesionStorageConst.RefreshTokenKey, refreshToken);
  }

  public getRefreshToken(): string | undefined
  {
    const res = this.cookieService.get(SesionStorageConst.RefreshTokenKey);
    return res ? JSON.parse(res) : undefined;

  }

  private removeRefreshToken(): void
  {
    this.remove(SesionStorageConst.RefreshTokenKey);
  }

  // #endregion

  // ********************************************************************
  //#region MSVC Authorization Token
  // ********************************************************************
  public saveMSVCToken(msvcToken: string): void
  {
    this.saveCookie(SesionStorageConst.MSVCToken, msvcToken);
  }

  private removeMSVCToken(): void
  {
    this.remove(SesionStorageConst.MSVCToken);
  }
  
  public getMSVCToken(): string | undefined
  {
    const res = this.cookieService.get(SesionStorageConst.MSVCToken);
    return res ? JSON.parse(res) : undefined;
  }
  // #endregion

  // ********************************************************************
  //#region User
  // ********************************************************************

  public saveUser(user: UserAccessResponse.IUserInfo): void
  {
    this.saveCookie(SesionStorageConst.UserKey, user);
  }

  public getUser(): UserAccessResponse.IUserInfo | undefined
  {
    const res: string = this.cookieService.get(SesionStorageConst.UserKey);
    return res ? JSON.parse(res) : undefined;
  }

  private removeUser(): void
  {
    this.remove(SesionStorageConst.UserKey);
  }

  public saveUserId(userId: string): void
  {
    this.saveCookie(SesionStorageConst.UserId, userId);
  }

  private removeUserId(): void
  {
    this.remove(SesionStorageConst.UserId);
  }

  // #endregion

  // ********************************************************************
  //#region CartId
  // ********************************************************************

  public saveCartCookie(cartCookie: string): void
  {
    this.saveCookie(SesionStorageConst.CartCookieKey, cartCookie);
  }

  public getCartCookie(): string
  {
    const res = this.cookieService.get(SesionStorageConst.CartCookieKey);
    return res ? JSON.parse(res) : undefined;
  }

  private removeCartCookie(): void
  {
    this.remove(SesionStorageConst.CartCookieKey);
  }
  // #endregion

  // ********************************************************************
  //#region CartItems
  // ********************************************************************

  public saveCartItems(items: any[]): void
  {
    this.localStorageService.removeItem(SesionStorageConst.CartItems);
    this.localStorageService.setItem(SesionStorageConst.CartItems, JSON.stringify(items));
  }

  public getCartItems(): any[] | undefined
  {
    const cartItems = this.localStorageService.getItem(SesionStorageConst.CartItems);
    return cartItems ? JSON.parse(cartItems) : undefined;
  }

  private removeCartItems(): void
  {
    this.localStorageService.removeItem(SesionStorageConst.CartItems);
  }

  // #endregion

  // ********************************************************************
  //#region CartCount
  // ********************************************************************

  public saveCartCount(count: number): void
  {
    this.removeCartCount();
    this.localStorageService.setItem(SesionStorageConst.CartItemsCount, JSON.stringify(count));
    this.currentValue.next(count);
  }

  public getCartCount(): number | undefined
  {
    const cartCount = this.localStorageService.getItem(SesionStorageConst.CartItemsCount);
    return cartCount ? +JSON.parse(cartCount) : undefined;
  }

  private removeCartCount(): void
  {
    this.localStorageService.removeItem(SesionStorageConst.CartItemsCount);
  }

  // #endregion

  // ********************************************************************
  //#region Total
  // ********************************************************************

  public saveCartTotal(total: number): void
  {
    this.removeCartTotal();
    this.localStorageService.setItem(SesionStorageConst.CartTotal, JSON.stringify(total));
  }

  public getCartTotal(): number | undefined
  {
    const total = this.localStorageService.getItem(SesionStorageConst.CartTotal);
    return total ? +JSON.parse(total) : undefined;
  }

  private removeCartTotal(): void
  {
    this.localStorageService.removeItem(SesionStorageConst.CartTotal);
  }
  // #endregion

  // ********************************************************************
  //#region To Add Package Items
  // ********************************************************************

  public saveCartToAddParams(packageId: number, coupon: string): void
  {
    if (packageId)
    {
      this.localStorageService.removeItem(SesionStorageConst.ToAddPackage);
      this.localStorageService.setItem(SesionStorageConst.ToAddPackage, packageId);
    }

    if (coupon)
    {
      this.localStorageService.removeItem(SesionStorageConst.ToAddCoupoin);
      this.localStorageService.setItem(SesionStorageConst.ToAddCoupoin, coupon);
    }
  }

  public getPackageIdToAdd(): number | undefined
  {
    const pkg = this.localStorageService.getItem(SesionStorageConst.ToAddPackage);

    return pkg ? +pkg : undefined;
  }

  public getCouponToAdd(): string | undefined
  {
    const coupon = this.localStorageService.getItem(SesionStorageConst.ToAddCoupoin);

    return coupon && coupon != "" ? coupon : undefined;
  }

  public remoteToAdd(): void
  {
    this.localStorageService.removeItem(SesionStorageConst.ToAddPackage);
    this.localStorageService.removeItem(SesionStorageConst.CartItems);
    this.localStorageService.removeItem(SesionStorageConst.ToAddCoupoin);
  }

  // #endregion

  // ********************************************************************
  //#region Hubspotutk cookie
  // ********************************************************************

  public getHubspotutk(): string
  {
    let result: string = '';

    const hubspotutkCookie = this.getHubspotutkCookie(SesionStorageConst.HubspotutkCookie);

    if (hubspotutkCookie !== undefined && hubspotutkCookie !== null)
    {
      result = hubspotutkCookie;
    }

    return result;
  }

  private getHubspotutkCookie(name: string): string
  {
    const ca: Array<string> = document.cookie.split(';');
    const caLen: number = ca.length;
    const cookieName = `${name}=`;
    let res: string = "";

    for (let i: number = 0; i < caLen; i += 1)
    {
      res = ca[i].replace(/^\s+/g, '');
      if (res.indexOf(cookieName) == 0)
      {
        return res.substring(cookieName.length, res.length);
      }
    }
    return '';
  }

  // #endregion

  // ********************************************************************
  //#region Purchase Suggestions
  // ********************************************************************

  public savePurchaseSuggestionSelected(value: IAdditionalPurchaseSuggestionSelected): void
  {
    this.localStorageService.setItem(LocalStorageConst.PurchaseSuggestionsDataSelected, JSON.stringify(value));
  }

  public getPurchaseSuggestionSelected(): IAdditionalPurchaseSuggestionSelected
  {
    const suggestionsSelected = this.localStorageService.getItem(LocalStorageConst.PurchaseSuggestionsDataSelected);
    return suggestionsSelected ? JSON.parse(suggestionsSelected) : null;
  }

  public removePurchaseSuggestionSelected(): void
  {
    this.localStorageService.removeItem(LocalStorageConst.PurchaseSuggestionsDataSelected);
  }

  //#endregion

  // ********************************************************************
  //#region Salesforce cookie
  // ********************************************************************

  public saveSFData(data: UserAccessResponse.IUserInfo): void
  {
    this.saveCookie(SesionStorageConst.UserId, data.id, true);
    this.saveCookie(SesionStorageConst.SFAccount, data.sfAccountId, true);
  }

  private removeSFData(): void
  {
    this.remove(SesionStorageConst.UserId);
    this.remove(SesionStorageConst.SFAccount);
  }

  // #endregion
}
