export class Tools
{
  public static cssRuleSet(selector: any, property: any, value: any, important: any): void
  {
    for (let i = document.styleSheets.length - 1; i >= 0; i--)
    {
      let currentStyleSheet: CSSStyleSheet;
      let rulesList: CSSRuleList;
      let currentRule: CSSStyleRule;

      try
      {
        currentStyleSheet = document.styleSheets[i];
        rulesList = currentStyleSheet.cssRules ?? currentStyleSheet.rules;
      }
      catch
      {
        continue;
      }

      for (let j = 0; j < rulesList.length; j++)
      {
        if (!(rulesList[j] instanceof CSSStyleRule))
        {
          continue;
        }

        currentRule = rulesList[j] as CSSStyleRule;

        if (currentRule.selectorText && currentRule.selectorText == selector)
        {
          if (typeof important == 'undefined')
          {
            currentRule.style.setProperty(property, value);
          }
          else
          {
            currentRule.style.setProperty(property, value, 'important');
          }
          break;
        }
      }
    }
  }

  public static isNullOrEmpty<T>(array: T[]): boolean
  {
    return !(array !== undefined && array !== null && array.length > 0);
  }

  public static getOffset(): number
  {
    return (new Date()).getTimezoneOffset();
  }

  public static isValidEnumValue(value: string, enumeration: object): boolean
  {
    if (value === undefined || value == null || value === '')
    {
      return false;
    }

    for (const key in enumeration)
    {
      if (value === enumeration[key])
      {
        return true;
      }
    }

    return false;
  }

  public static converteStringToBase64(data: string): string
  {
    return btoa(data);
  }

  // ********************************************************************************
  // #region Filter Helpers
  // ********************************************************************************

  public static groupBy(date: any[], key: string): any
  {
    return date.reduce((result, currentValue) =>
    {
      (result[currentValue[key]] = result[currentValue[key]] || []).
        push(currentValue);
      return result;
    }, {});
  }

  public static sortBy(array: any[], key: string): any[]
  {
    try
    {
      return array.sort((a, b) =>
      {
        return (a[key] > b[key]) ? 1 : (a[key] < b[key]) ? -1 : 0;
      });
    }
    catch (error)
    {
      return array;
    }
  }

  public static compare(a: number | string, b: number | string, isAsc: boolean): number
  {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  public static convertServiceData(serviceData: string): Record<string, number>
  {
    return serviceData.split(',').reduce((mappingAccumulator, idPair) =>
    {
      const [typeId, idValue] = idPair.split('=');
      mappingAccumulator[typeId] = +idValue;
      return mappingAccumulator;
    }, {} as Record<string, number>);
  }
}
