export interface IPixelPurchaseDataModel 
{
    value: number;
    currency: string;
    content_name: string;
    content_type: string;
    num_items: number;
    content_ids: string[];
}

export class PixelPurchaseDataModel implements IPixelPurchaseDataModel
{
    public value: number;
    public currency: string;
    public content_name: string;
    public content_type: string;
    public num_items: number;
    public content_ids: string[];

    constructor(value: number, currency: string, content_name: string, content_type: string, num_items: number, content_ids: string[])
    {
        this.value = value;
        this.currency = currency;
        this.content_name = content_name;
        this.content_type = content_type;
        this.num_items = num_items;
        this.content_ids = content_ids;
    }
}