export const Environment = {
        C_E_ApiKey: ')PXB-c|*-[zDq2+-PW/RUk23{o*}~1)+zNG3IWKN&KH?uwq)gJ0w^EIjNRM`^N|(',
        C_MS_ApiKey: 'lfdrxAab|9UWV+p%QsCJ=.7;crR:0d|g5Z>QTt3Mn*40A0mxy%g|eE4ddbc3U6&',

        appVersion: '0.0.0',
        Production: false,
        Local: false,
        ChatBot: true,

        Webapi: 'https://app-ecommercedockerwebapi-qa-01.azurewebsites.net/',
        MicroserviceURL: "https://app-cartmsvc-qa-01.azurewebsites.net/",
        PortalAkkyURL: "https://extranet.rar.devl2.akky.proyectos.dvl.nic.com.mx/",
        
        SchedulerURL: "https://outlook.office365.com/owa/calendar/AKKY@iar.mx/bookings/",
        
        PYMESPackage: 21,
        DigitizePackage: 20,
         
        UrlSalesForce: "https://nic20--uat.sandbox.my.salesforce.com",
        UrlChatBot: "https://nic20--uat.sandbox.my.salesforce-sites.com/takesurvey",
        UrlAvatarChatBot: "https://nic20--uat.sandbox.my.salesforce-sites.com/postChat/resource/AkkyAvatarChat",
        SalesForceId: "00D8F0000008ha3",
        BaseLiveAgentContentURL: "https://c.la1-core1.sfdc-yfeipo.salesforceliveagent.com/content",
        DeploymentId: "5725w000000QACy",
        ButtonId: "5738F000000GmdD",
        BaseLiveAgentURL: "https://d.la1-core1.sfdc-yfeipo.salesforceliveagent.com/chat",
        EswLiveAgentDevName: "Chat_Akky_P3",
        
        emailId: "136147416138",
        emailIdHubspotWithCoupon: "146788369925"
    };
    