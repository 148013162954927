export class HtmlExtractUtil
{
    public static readonly DOM_COVERAGE_CLASS = 'domCoverage';
    public static readonly DOM_PRICE_CLASS = 'domPrice';
  
    public static parseCoverage(currentPrice: string): string
    {
        const itemInfo: Document = new DOMParser().parseFromString(currentPrice, 'text/html');
        const coverageElement = itemInfo.getElementsByClassName(this.DOM_COVERAGE_CLASS)[0] as HTMLElement;
        return coverageElement ? coverageElement.innerHTML : "";
    }
  
    public static parsePrice(currentPrice: string): string
    {
        const itemInfo: Document = new DOMParser().parseFromString(currentPrice, 'text/html');
        const priceElement = itemInfo.getElementsByClassName(this.DOM_PRICE_CLASS)[0] as HTMLElement;
        return priceElement ? priceElement.innerHTML.replace(/\D/g, "").split(",").join("") : "";
    }
}