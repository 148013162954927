import { Injectable } from '@angular/core';
import { FBP_EVENTS, FBP_TACK_TYPES } from '@core-constants/fbp-trackting.const';
import { PixelPurchaseDataModel } from '@core-models/pixel.model';

declare let fbq: any;

@Injectable({
  providedIn: 'root'
})
export class PixelService
{
  constructor() { }

  public trackPurchaseEvent (data: PixelPurchaseDataModel = null): void
  {
    if (fbq)
    {
      fbq(FBP_TACK_TYPES.TRACK, FBP_EVENTS.PURCHASE, data);
    }
  }
}
